import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Banking App Template | Banking App Design | Uizard"
    category="Tablet Templates"
    url="/templates/tablet-templates/online-banking-tablet-app/"
    metaDescription="Design an online banking app for tablets in minutes with Plutus, Uizard's banking app design template. Explore now."
    description="
    h2:Online banking tablet app design made easy with Plutus
    <br/>
    Bring your tablet app idea to life with Plutus, our online banking app template design. Plutus boasts a stunningly minimalist and chic design however, the <a:https://uizard.io/templates/>UI design template</a> is fully customizable to help you bring your idea to life with ease.
    <br/>
    h3:Visualize your own banking app for tablet collaboratively, in real-time
    <br/>
    With drag-and-drop components and easy page linking, you can create your banking tablet app design in minutes, whilst also collaborating in real-time with your team or colleagues. Uizard's mission is the democratization of design, and the Plutus banking app design template for tablets is no exception!
    <br/>
    h3:Drag and drop, customize and create. Bring your design to life
    <br/>
    To get started bringing your banking app design idea to life, simply open Uizard, select the Plutus template, and get customizing. Plutus comes preloaded with all the screens any reputable banking app should have; however, with the power of Uizard's vast customization options, you can really create something unique.
    "
    pages={[
      "An app landing page, log-in screen, and security verification screen",
      "Account settings screen, housing all the form options you would expect within a banking app",
      "A stunning dashboard screen, demonstrating a wide array of information to really bring your tablet app design to life",
      "Transactions, Transfer overview, and Accounts and cards screens to demonstrate the core functionality of your tablet banking app design",
    ]}
    projectCode="Pj0eXEBlKwsqLy87XjB6"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Plutus, a tablet banking app: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Plutus, a tablet banking app: transactions overview page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Plutus, a tablet banking app: accounts and cards page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Plutus, a tablet banking app: investment portfolio page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary for the template Plutus, a tablet banking app"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/banking-tablet-app/bank-tablet-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/banking-tablet-app/bank-tablet-app-dashboard.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/banking-tablet-app/bank-tablet-app-accounts.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/banking-tablet-app/bank-tablet-app-investments.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/banking-tablet-app/bank-tablet-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
